import {graphql, Link, StaticQuery} from "gatsby"
import {FixedObject, FluidObject} from "gatsby-image"
import React, {useState} from "react"
import {GatsbyImage, GatsbyImageProps} from "gatsby-plugin-image"

type StaticQueryData = {
  readonly logo: {
    publicURL: string
    childImageSharp: {
      gatsbyImageData: GatsbyImageProps
    }
  }
  readonly site: {
    siteMetadata: {
      learningPlatformLink: string
      signInLink: string
      signUpLink: string
    }
  }
}

interface Props {
  readonly hideMenu?: boolean
}

const Navbar: React.FC<Props> = ({hideMenu}) => {
  const [open, setOpen] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: {eq: "Tercen_logo.png"}) {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 258, height: 66)
            }
          }
          site {
            siteMetadata {
              learningPlatformLink
              signInLink
              signUpLink
            }
          }
        }
      `}
      render={(data: StaticQueryData): React.ReactElement | null => {
        return (
          <header className="w-full sticky bg-white top-0 z-50">
            <nav className="container flex items-center justify-between flex-wrap p-2">
              <Link to={`/`}>
                <GatsbyImage className="" image={data.logo.childImageSharp.gatsbyImageData} alt="Logo" />
              </Link>
              <div className="block lg:hidden">
                <button
                  className="flex items-center px-3 py-2 border rounded border-primary hover:text-white hover:border-white"
                  onClick={() => setOpen(!open)}>
                  <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </svg>
                </button>
              </div>
              <div
                className={
                  "w-full block flex-grow lg:divide-x self-end pb-1 lg:divide-gray-300 lg:flex lg:items-center lg:w-auto" +
                  (open ? "flex" : " hidden")
                }>
                <div className="text-sm font-light lg:flex-grow text-right">
                  <Link
                    to={`/about-us`}
                    className="block mt-4 lg:inline-block lg:mt-0 hover:text-primary mr-8 transition duration-300 ease-in-out hover:text-tercen-blue"
                    activeClassName="text-tercen-orange">
                    About
                  </Link>
                  <Link
                    to={`/pricing`}
                    className="block mt-4 lg:inline-block lg:mt-0 hover:text-primary mr-8 transition duration-300 ease-in-out hover:text-tercen-blue"
                    activeClassName="text-tercen-orange">
                    {" "}
                    Pricing{" "}
                  </Link>
                  <a
                    target="_blank"
                    href={data.site.siteMetadata.learningPlatformLink}
                    className="block mt-4 lg:inline-block lg:mt-0 hover:text-primary mr-8 transition duration-300 ease-in-out hover:text-tercen-blue">
                    {" "}
                    Learn{" "}
                  </a>
                </div>
                <div className="text-sm font-light text-right">
                  <a
                    href={data.site.siteMetadata.signInLink}
                    className="block mt-4 text-tercen-blue lg:inline-block lg:mt-0 mx-8 transition duration-300 ease-in-out hover:text-black">
                    {" "}
                    Sign In{" "}
                  </a>
                  <a
                    href={data.site.siteMetadata.signUpLink}
                    className="block mt-4 text-center lg:inline-block lg:mt-0 mr-8 bg-tercen-green-base transition duration-300 ease-in-out hover:bg-tercen-green-hover text-white py-1 px-4 rounded-sm">
                    {" "}
                    Sign Up{" "}
                  </a>
                </div>
              </div>
            </nav>
          </header>
        )
      }}
    />
  )
}

export default Navbar
